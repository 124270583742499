<template>
    <div class="preview-container">
        <div
            class="preview-item"
            v-for="item in schema"
            :key="item.id"
            v-b-tooltip.hover.top="{
                variant: 'success',
                customClass: 'k-tooltip-2',
            }"
            :title="item.name"
        >
            <template v-if="item.type === 'color'">
                <div
                    class="preview-item-block color"
                    :style="`background:${parseColor(values[item.id])}`"
                ></div>
            </template>
            <template v-else-if="item.type === 'icon'">
                <div
                    class="preview-item-block icon"
                    :style="`background-image:url(${values[item.id]})`"
                ></div>
            </template>
            <template v-else
                ><div class="preview-item-block text-center">
                    <small>Aperçu <br />non dispo</small>
                </div></template
            >
        </div>
    </div>
</template>

<script>
export default {
    props: {
        schema: Array,
        values: Object,
    },

    methods: {
        parseColor(input) {
            try {
                const arr = JSON.parse(`[${input.slice(1, -1)}]`);
                arr[3] = arr[3] / 255 || 0;
                return `rgba(${arr[0]},${arr[1]},${arr[2]},${arr[3]})`;
            } catch (e) {
                return "magenta";
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.preview-container {
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    padding: 0 5px;
    background: #eee8;
}

.preview-item {
    min-width: 40px;
    height: 40px;
}

.preview-item-block {
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: inset -1px -1px #ddd, inset 1px 1px #222;
    padding: 2px;
    line-height: 1.15;
    cursor: help;
}

.preview-item-block.color {
    border-radius: 12px;
}

.preview-item-block.icon {
    border-radius: 3px;
    background-size: contain;
    background-origin: content-box;
    background-color: #888;
    background-repeat: no-repeat;
}
</style>
