<template>
    <div :class="{ 'depth-gutter': depth > 0 }" v-show="visible">
        <div
            class="arbo-node-block"
            :class="{
                highlighted,
                selected,
                root: depth == 0,
                templated,
            }"
            @click="selectMe"
        >
            <template v-if="depth == 0">
                <div>Arborescence</div>
                <div style="flex-grow: 10" />
                <div class="text-center text-kalkin-1">
                    <small>Styles par défaut</small>
                </div>
            </template>
            <template v-else>
                <template v-if="templated">
                    <div class="arbo-node-pill bg-kalkin-1">
                        <div>- Modèle -</div>
                    </div>
                </template>
                <div class="arbo-node-pill">
                    <div>Niveau {{ depth }}</div>
                </div>
                <div>
                    <div class="k-flex-row k-gap-m">
                        <small class="text-kalkin-2"> Nom du menu</small>
                        <small
                            class="rounded bg-kalkin-3 px-2 text-light"
                            v-show="menuType"
                        >
                            <b-icon-tag-fill /> {{ menuType }}
                        </small>
                    </div>
                    <div>
                        {{ menu.name }}
                    </div>
                </div>
                <div style="flex-grow: 10" />

                <template v-if="!hasStyle">
                    <div
                        class="arbo-node-style-origin"
                        v-b-tooltip.hover.left="{
                            variant: 'success',
                            customClass: 'k-tooltip-2',
                        }"
                        title="Style hérité du menu parent"
                        @mouseenter="highlightParent(true)"
                        @mouseleave="highlightParent(false)"
                    >
                        <b-icon-arrow90deg-up />
                    </div>
                </template>
            </template>
            <div class="arbo-node-style-form">
                <GenericPreview :schema="templateConfig" :values="style" />
            </div>
        </div>
        <div class="arbo-node-children" v-if="hasChildren">
            <div
                class="arbo-node-control pointer user-select-none"
                :class="{ expanded: showChildren }"
                v-if="menu.children.length"
                @click="showChildren = !showChildren"
            >
                <span v-show="!showChildren">
                    <b-icon-plus-square-fill /> Afficher les sous-menus masqués
                </span>
                <span v-show="showChildren">
                    <b-icon-dash-square-fill />
                </span>
            </div>
            <template v-for="menu in menu.children">
                <ArboNode
                    :menu="menu"
                    :template-config="templateConfig"
                    :selected-menu-id="selectedMenuId"
                    :key="menu.id"
                    :depth="depth + 1"
                    :visible="showChildren"
                    @select="select"
                />
            </template>
        </div>
    </div>
</template>

<script>
import GenericPreview from "@/components/form-generator/GenericPreview";
import MenuData from "@/model/menu";

export default {
    components: {
        GenericPreview,
    },

    name: "ArboNode",

    props: {
        menu: Object,
        selectedMenuId: [String, Number],
        templateConfig: Array,
        depth: { type: Number, default: 0 },
        visible: { type: Boolean, default: true },
    },

    data: function() {
        return {
            hasChildren: this.menu.children && this.menu.children.length > 0,
            hasStyle: this.menu.MenuStyles && this.menu.MenuStyles.length > 0,
            showChildren: true,
            highlighted: false,
        };
    },

    computed: {
        menuType() {
            return MenuData.getTypeLabel(
                this.menu.config && this.menu.config.type,
            );
        },

        style() {
            if (this.hasStyle) {
                return this.menu.MenuStyles[0].style.global;
            } else if (this.depth === 0) {
                return this.templateConfig.reduce((acc, cur) => {
                    acc[cur.id] = cur.default;
                    return acc;
                }, {});
            } else {
                return this.$parent.style;
            }
        },

        templated() {
            return this.menu && this.menu.templateId;
        },

        selected() {
            return this.depth > 0 && this.selectedMenuId === this.menu.id;
        },
    },

    watch: {
        menu: {
            handler() {
                this.hasChildren =
                    this.menu.children && this.menu.children.length > 0;
                this.hasStyle =
                    this.menu.MenuStyles && this.menu.MenuStyles.length > 0;
            },
            deep: true,
        },
    },

    methods: {
        highlightParent(state) {
            this.$parent.highlighted = state;
        },

        selectMe() {
            if (this.depth > 0) {
                this.$emit("select", this.menu.id);
            }
        },

        select(data) {
            this.$emit("select", data);
        },
    },
};
</script>

<style lang="scss" scoped>
.arbo-node-style-origin {
    width: 35px;
    height: 35px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary-color;
    background: #eee8;
    cursor: help;
}

.arbo-node-style-form {
    min-width: 50px;
    height: 45px;
    border: 1px solid #ddd;
    border-radius: 6px;
}
</style>
