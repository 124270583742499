<template>
    <div>
        <GenericForm v-bind="genericFormConfig" @updated="onChange" />
    </div>
</template>

<script>
import Vue from "vue";
import GenericForm from "@/components/form-generator/GenericForm";
import Formatter from "@/tools/format";
import { debounce } from "lodash";

export default {
    components: {
        GenericForm,
    },

    props: {
        menuId: { type: [String, Number], required: true },
        templateConfig: Array,
        menuStyle: Object,
        project: Object,
    },

    data: function() {
        return {
            localGlobalStyle:
                this.menuStyle && this.menuStyle.global
                    ? Vue.util.extend({}, this.menuStyle.global)
                    : {},
        };
    },

    watch: {
        menuStyle() {
            if (this.menuStyle && this.menuStyle.global) {
                this.localGlobalStyle = Vue.util.extend(
                    {},
                    this.menuStyle.global,
                );
            }
        },
    },

    computed: {
        genericFormConfig() {
            let config = {};
            try {
                config = {
                    schema: {
                        ...Formatter.convertCOREtoFORM(this.templateConfig),
                    },
                    model: this.localGlobalStyle,
                    payload: { project: this.project },
                };
            } catch (error) {
                // Do nothing
            }
            return config;
        },
    },

    methods: {
        onChange(data) {
            this.localGlobalStyle = data;
            this.save();
        },

        save: debounce(function() {
            this.$emit("update-style", {
                id: this.menuId,
                style: {
                    ...this.menuStyle,
                    global: this.localGlobalStyle,
                },
            });
        }, 100),
    },
};
</script>

<style lang="scss" scoped></style>
