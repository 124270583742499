<template>
    <SplitPanel>
        <template v-slot:left>
            <TreeArboStyle
                :arbo="arboRoot"
                :template-config="templateConfig"
                :selected-menu-id="selectedMenuId"
                @select="onMenuSelection"
            />
        </template>
        <template v-slot:menu>
            <ToolboxArboMenu :projectId="projectId" :arbo="arbo" :current="2" />
        </template>
        <template v-slot:right>
            <div class="menu">
                <template v-if="!selectedMenuId">
                    <div class="text-kalkin-1">Aucun menu selectionné</div>
                </template>
                <template v-else-if="!selectedStyle">
                    <div class="p-3">
                        <div class="text-kalkin-1">
                            Ce menu possède un style hérité de son parent
                        </div>
                        <small>
                            Vous pouvez annuler cet héritage et personnaliser ce
                            menu et ses descendants avec le style de votre choix
                            en cliquant ci-dessous
                        </small>
                    </div>
                    <div>
                        <b-button
                            size="sm"
                            variant="kalkin-1"
                            @click="initMenuStyle"
                        >
                            Personnaliser ce menu
                        </b-button>
                    </div>
                </template>
                <template v-else>
                    <div class="my-3 text-center">
                        <small>Edition du style pour le menu</small>
                        <div class="text-kalkin-1">
                            {{ menus[selectedMenuId].name }}
                        </div>
                    </div>
                    <FormEditGlobal
                        class="panel"
                        :menu-id="selectedMenuId"
                        :template-config="templateConfig"
                        :menu-style="selectedStyle"
                        :project="project"
                        @update-style="onStyleUpdate"
                    />
                    <div class="my-3 text-center">
                        <small>
                            <b-icon-gear-fill />
                            Edition avancée du style
                        </small>
                    </div>
                    <FormEditAdvanced
                        class="panel"
                        :menu-id="selectedMenuId"
                        :menu-style="selectedStyle"
                        @update-style="onStyleUpdate"
                    />
                    <div class="flex-grow-1" />
                    <div class="panel text-center">
                        <b-button
                            size="sm"
                            variant="outline-danger"
                            v-b-modal.ModalConfirmDelete
                        >
                            Supprimer le style
                        </b-button>
                    </div>
                </template>
                <ModalConfirmDelete @confirmed="onDeleteConfirmed" />
            </div>
        </template>
    </SplitPanel>
</template>

<script>
import { get as _get } from "lodash";
import SplitPanel from "@/components/ui/SplitPanel";
import TreeArboStyle from "@/components/model/arbo/TreeArboStyle";
import ToolboxArboMenu from "@/components/model/arbo/ToolboxArboMenu";
import FormEditGlobal from "@/components/model/menustyle/FormEditGlobal";
import FormEditAdvanced from "@/components/model/menustyle/FormEditAdvanced";
import ModalConfirmDelete from "@/components/modal/ModalConfirmDelete";

export default {
    components: {
        SplitPanel,
        TreeArboStyle,
        ToolboxArboMenu,
        FormEditGlobal,
        FormEditAdvanced,
        ModalConfirmDelete,
    },

    data: function() {
        return {
            projectId: this.$route.params.projectId,
            configId: this.$route.params.configId,
            arboId: this.$route.params.arboId,
            project: null,
            arbo: undefined,
            arboRoot: null,
            menus: {},
            templateConfig: [],
            selectedMenuId: null,
        };
    },

    computed: {
        selectedStyle() {
            if (this.templateConfig) {
                const ms = this.menus[this.selectedMenuId].MenuStyles;
                if (ms && ms.length > 0) {
                    return ms[0].style;
                }
            }
            return null;
        },
    },

    methods: {
        async fetchProject() {
            this.project = await this.$store.dispatch("project/getProject", {
                projectId: this.projectId,
            });
        },

        async fetchArbo() {
            this.arbo = await this.$store.dispatch("project/getArbo", {
                projectId: this.projectId,
                arboId: this.arboId,
            });
            const raw = await this.$store.dispatch(
                "application/getMenusAssociable",
                {
                    projectId: this.projectId,
                    configId: this.configId,
                    arboId: this.arboId,
                },
            );

            this.menus = raw.reduce((acc, cur) => {
                cur.children = [];
                acc[cur.id] = cur;
                return acc;
            }, {});

            this.arboRoot = [];
            for (const menu of raw) {
                if (menu.parentId) {
                    this.menus[menu.parentId].children.push(menu);
                } else {
                    this.arboRoot.push(menu); //roots
                }
            }
        },

        async fetchBuildConfig() {
            const appconfig = await this.$store.dispatch(
                "application/getApplicationConfigBuildConfig",
                {
                    projectId: this.projectId,
                    configId: this.configId,
                },
            );
            this.templateConfig = _get(
                appconfig,
                [
                    "ApplicationBuild",
                    "TemplateConfig",
                    "config",
                    "menu_variables",
                ],
                null,
            );
        },

        async refreshMenuStyle(menuId) {
            this.$set(
                this.menus[menuId].MenuStyles,
                [0],
                await this.$store.dispatch("application/getMenuStyle", {
                    projectId: this.projectId,
                    configId: this.configId,
                    menuId,
                }),
            );
        },

        async initMenuStyle() {
            if (this.selectedMenuId && !this.selectedStyle) {
                let breaker = 15;
                let style = null;
                let currentMenuId = this.selectedMenuId;
                do {
                    const { parentId } = this.menus[currentMenuId];
                    if (!parentId) break;
                    style = _get(this.menus[parentId], "MenuStyles[0].style");
                    currentMenuId = parentId;
                } while (breaker-- > 0 && !style);

                await this.$store.dispatch("application/createMenuStyle", {
                    projectId: this.projectId,
                    configId: this.configId,
                    menuId: this.selectedMenuId,
                    style: style || {
                        global: this.templateConfig.reduce((acc, cur) => {
                            acc[cur.id] = cur.default;
                            return acc;
                        }, {}),
                    },
                });
                await this.refreshMenuStyle(this.selectedMenuId);
            }
        },

        onMenuSelection(id) {
            const rightContainer = document.getElementById("k-sp-right")
                .children[0];
            rightContainer.scrollTop = 0;

            if (this.selectedMenuId !== id) {
                this.selectedMenuId = id;
            } else {
                this.selectedMenuId = null;
            }
        },

        async onStyleUpdate({ id, style }) {
            let menuId = id;
            if (menuId) {
                await this.$store.dispatch("application/updateMenuStyle", {
                    projectId: this.projectId,
                    configId: this.configId,
                    menuId,
                    style,
                });
                await this.refreshMenuStyle(menuId);
            }
        },

        async onDeleteConfirmed() {
            if (this.selectedStyle) {
                await this.$store.dispatch("application/deleteMenuStyle", {
                    projectId: this.projectId,
                    configId: this.configId,
                    menuId: this.selectedMenuId,
                });
                this.menus[this.selectedMenuId].MenuStyles.splice(0);
            }
        },
    },

    async beforeMount() {
        await this.fetchProject();
        await this.fetchArbo();
        await this.fetchBuildConfig();
    },
};
</script>

<style lang="scss" scoped>
.menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    min-height: 100%;
    padding: 8px 0;

    background: #eee;
}

.panel {
    width: calc(100% - 16px);
    padding: 15px 5px;
    border: 1px solid #ccc;
    border-radius: 5px;

    background: #fff;
}
</style>
