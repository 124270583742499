<template>
    <div>
        <b-form-group>
            <label>
                Surcharge du style
                <b-icon-question-circle-fill
                    id="help-override"
                    class="text-secondary"
                />
            </label>
            <b-form-checkbox v-model="isChecked" @change="overrideDistance">
                Forcer l'affichage de la distance
                <b-icon-question-circle-fill
                    id="help-affichage"
                    class="text-secondary"
                />
                <b-popover
                    target="help-affichage"
                    triggers="hover"
                    placement="topleft"
                >
                    <template #title>
                        Forcer l'affichage de la distance à la place de la
                        durée, s'applique uniquement pour le template Komura.
                    </template>
                    <pre>{{ distanceExample }}</pre>
                </b-popover>
            </b-form-checkbox>
            <JSONTextArea
                v-model="localOverrideStyle"
                @input="onChanged"
                @validate="isOverrideStyleValid = $event"
            />
            <b-popover
                target="help-override"
                triggers="hover"
                placement="topleft"
            >
                <template #title>Exemple de surcharge</template>
                <pre>{{ overrideExample }}</pre>
            </b-popover>
        </b-form-group>

        <b-form-group>
            <label>
                Variantes du style
                <b-icon-question-circle-fill
                    id="help-variant"
                    class="text-secondary"
                />
            </label>
            <JSONTextArea
                v-model="localVariantStyle"
                @input="onChanged"
                @validate="isVariantStyleValid = $event"
            />
            <b-popover
                target="help-variant"
                triggers="hover"
                placement="topleft"
            >
                <template #title>Exemple de variantes</template>
                <pre>{{ variantExample }}</pre>
            </b-popover>
        </b-form-group>

        <div class="text-center">
            <small class="text-danger" v-show="!isValid">
                <b-icon-exclamation-circle /> Echec de la sauvegarde, au moins
                un champ est invalide
            </small>
        </div>
    </div>
</template>

<script>
import { debounce } from "lodash";
import JSONTextArea from "@/components/ui/item/JSONTextArea";

export default {
    components: {
        JSONTextArea,
    },

    props: {
        menuId: { type: [String, Number], required: true },
        menuStyle: Object,
    },

    data: function() {
        return {
            isChecked: false,
            localOverrideStyle:
                this.menuStyle && this.menuStyle.override
                    ? JSON.stringify(this.menuStyle.override)
                    : null,
            localVariantStyle:
                this.menuStyle && this.menuStyle.variant
                    ? JSON.stringify(this.menuStyle.variant)
                    : null,
            isOverrideStyleValid: true,
            isVariantStyleValid: true,

            distanceOverride: JSON.stringify(
                {
                    fiches: {
                        RandoMenuItem_overrideKey: "distance",
                        RandoMenuItem_suffixe: "km",
                        RandoMenuItem_useDistanceAsDefault: true,
                    },
                },
                null,
                2,
            ),
            distanceExample: JSON.stringify(
                {
                    menu: {
                        FirstMenu_background_color: "(180,180,180,255)",
                        FirstMenu_background_image:
                            "http://example/hautes_terres_ski_de_rando.png",
                        FirstMenu_background_size: "(500,100)",
                        FirstMenu_highlight_color: "(255,255,255,255)",
                        FirstMenu_Margin: "(0,0,0,5)",
                    },
                    fiches: {
                        RandoMenuItem_overrideKey: "distance",
                        RandoMenuItem_suffixe: "km",
                        RandoMenuItem_useDistanceAsDefault: true,
                    },
                },
                null,
                2,
            ),
            overrideExample: JSON.stringify(
                {
                    menu: {
                        SimpleListMenu_background_color: "(255,255,255,255)",
                    },
                    fiches: {
                        Track_mainColor: "(142,67,173,255)",
                        RandoMenuItem_altColor: "(142,67,173,255)",
                    },
                },
                null,
                2,
            ),
            variantExample: JSON.stringify(
                [
                    {
                        field: "content.color",
                        value: "rouge",
                        override: {
                            Track_mainColor: "(255,0,0,255)",
                        },
                    },
                    {
                        field: "content.color",
                        value: "vert",
                        override: {
                            Track_mainColor: "(0,255,0,255)",
                        },
                    },
                    {
                        field: "content.color",
                        value: "bleu",
                        override: {
                            Track_mainColor: "(0,0,255,255)",
                        },
                    },
                ],
                null,
                2,
            ),
        };
    },

    watch: {
        menuStyle() {
            if (this.menuStyle && this.menuStyle.override) {
                this.localOverrideStyle = JSON.stringify(
                    this.menuStyle.override,
                );
                this.checkFiches();
            } else {
                this.localOverrideStyle = null;
            }
            if (this.menuStyle && this.menuStyle.variant) {
                this.localVariantStyle = JSON.stringify(this.menuStyle.variant);
            } else {
                this.localVariantStyle = null;
            }
        },
    },

    computed: {
        isValid() {
            return this.isOverrideStyleValid && this.isVariantStyleValid;
        },
    },

    mounted() {
        this.checkFiches();
    },

    methods: {
        checkFiches() {
            if (this.menuStyle && this.menuStyle.override) {
                if (this.menuStyle.override.fiches) {
                    this.isChecked = true;
                } else {
                    this.isChecked = false;
                }
            }
        },

        onChanged() {
            if (this.isOverrideStyleValid && this.isVariantStyleValid) {
                this.save();
            }
        },

        async overrideDistance() {
            let json = JSON.parse(this.localOverrideStyle);
            if (!json) {
                json = JSON.parse(this.distanceOverride);
            } else if (this.isChecked && !json.fiches) {
                let localOverrideStyle = JSON.parse(this.distanceOverride);
                json.fiches = localOverrideStyle.fiches;
            } else if (!this.isChecked && json && json.fiches) {
                delete json.fiches;
            }
            this.localOverrideStyle = JSON.stringify(json);
            await this.save();
        },

        save: debounce(function() {
            this.$emit("update-style", {
                id: this.menuId,
                style: {
                    ...this.menuStyle,
                    override: JSON.parse(this.localOverrideStyle) || undefined,
                    variant: JSON.parse(this.localVariantStyle) || undefined,
                },
            });
        }, 100),
    },
};
</script>

<style lang="scss" scoped></style>
