var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],class:{ 'depth-gutter': _vm.depth > 0 }},[_c('div',{staticClass:"arbo-node-block",class:{
            highlighted: _vm.highlighted,
            selected: _vm.selected,
            root: _vm.depth == 0,
            templated: _vm.templated,
        },on:{"click":_vm.selectMe}},[(_vm.depth == 0)?[_c('div',[_vm._v("Arborescence")]),_c('div',{staticStyle:{"flex-grow":"10"}}),_vm._m(0)]:[(_vm.templated)?[_vm._m(1)]:_vm._e(),_c('div',{staticClass:"arbo-node-pill"},[_c('div',[_vm._v("Niveau "+_vm._s(_vm.depth))])]),_c('div',[_c('div',{staticClass:"k-flex-row k-gap-m"},[_c('small',{staticClass:"text-kalkin-2"},[_vm._v(" Nom du menu")]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(_vm.menuType),expression:"menuType"}],staticClass:"rounded bg-kalkin-3 px-2 text-light"},[_c('b-icon-tag-fill'),_vm._v(" "+_vm._s(_vm.menuType)+" ")],1)]),_c('div',[_vm._v(" "+_vm._s(_vm.menu.name)+" ")])]),_c('div',{staticStyle:{"flex-grow":"10"}}),(!_vm.hasStyle)?[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:({
                        variant: 'success',
                        customClass: 'k-tooltip-2',
                    }),expression:"{\n                        variant: 'success',\n                        customClass: 'k-tooltip-2',\n                    }",modifiers:{"hover":true,"left":true}}],staticClass:"arbo-node-style-origin",attrs:{"title":"Style hérité du menu parent"},on:{"mouseenter":function($event){return _vm.highlightParent(true)},"mouseleave":function($event){return _vm.highlightParent(false)}}},[_c('b-icon-arrow90deg-up')],1)]:_vm._e()],_c('div',{staticClass:"arbo-node-style-form"},[_c('GenericPreview',{attrs:{"schema":_vm.templateConfig,"values":_vm.style}})],1)],2),(_vm.hasChildren)?_c('div',{staticClass:"arbo-node-children"},[(_vm.menu.children.length)?_c('div',{staticClass:"arbo-node-control pointer user-select-none",class:{ expanded: _vm.showChildren },on:{"click":function($event){_vm.showChildren = !_vm.showChildren}}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showChildren),expression:"!showChildren"}]},[_c('b-icon-plus-square-fill'),_vm._v(" Afficher les sous-menus masqués ")],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.showChildren),expression:"showChildren"}]},[_c('b-icon-dash-square-fill')],1)]):_vm._e(),_vm._l((_vm.menu.children),function(menu){return [_c('ArboNode',{key:menu.id,attrs:{"menu":menu,"template-config":_vm.templateConfig,"selected-menu-id":_vm.selectedMenuId,"depth":_vm.depth + 1,"visible":_vm.showChildren},on:{"select":_vm.select}})]})],2):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center text-kalkin-1"},[_c('small',[_vm._v("Styles par défaut")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"arbo-node-pill bg-kalkin-1"},[_c('div',[_vm._v("- Modèle -")])])}]

export { render, staticRenderFns }